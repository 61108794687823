<template>
  <div>
    <div class="group_details_box">
      <!--规格-->
      <div class="group_details_all" @click="btnRegulaTions()">
        <div class="group_details_l">
          <span class="group_details_name">规格:</span>
          <span class="group_details_title">

            <span v-if="$store.state.skuInfo&&productInfo.saleList&&productInfo.saleList.length" style="color:red">
              {{ $store.state.skuInfo.txt }}
              <span style="color:#666">x{{ $store.state.skuInfo.goodsCount }}</span>
            </span>

            <span v-else style="color:red">
              {{ showSkuTxt }}
              <span style="color:#666">x1</span>
            </span>

          </span>
        </div>
        <div class="group_details_r">
          <img src="../../assets/images/MoreIocn.png" alt="">
        </div>
      </div>
      <!--地址-->
      <div class="group_details_all" @click="addRess()">
        <div class="group_details_l">
          <span class="group_details_name">送至:</span>
          <span class="group_details_title">{{ addressTxt }}</span>
        </div>
        <div class="group_details_r">
          <img src="../../assets/images/MoreIocn.png" alt="">
        </div>
      </div>
      <!--运费-->
      <div class="group_details_all">
        <div class="group_details_l">
          <span class="group_details_name">运费:</span>
          <span class="group_details_title">{{ productInfo.goodFreight }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable eqeqeq */

import { addressH5List } from '@/services/userApi'

export default {
  name: 'GoogGroupDetails',
  props: {
    'productInfo': {
      type: null,
      default: null
    },
    'areaInfo': {
      type: null,
      default: null
    }
  },
  data() {
    return {
      addressTxt: '请选择配送地'// 地址信息
    }
  },
  computed: {
    showSkuTxt() {
      let str = ''
      if (this.productInfo.saleList && this.productInfo.saleList.length !== 0) {
        this.productInfo.saleList.forEach((e, i) => {
          str += e.buttons[0].text + ' '
        })
      }

      return str + this.productInfo.skuSale
    }
  },
  mounted() {
    this.getAddressList()
  },
  methods: {
    // 规格
    btnRegulaTions: function() {
      this.$store.state.showLoading = true
      this.$store.commit('btnIsShow', true) // 通过vuex 改变 子组件的状态
      this.$parent.$refs.btn.dataAll() // 调用子组件 按钮的方法
    },
    // 选择地址
    addRess: function() {
      if (localStorage.getItem('uid') && localStorage.getItem('token')) {
        this.$router.push('/addressList?id=' + this.addressInfo.id)
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },
    getAddressList() { // 先从本地中读取，如果本地没有，去地址列表拿第一条
      if (window.localStorage.getItem('addressItem')) { // 选择地址后的操作
        const data = JSON.parse(window.localStorage.getItem('addressItem'))
        this.addressInfo = data
        this.addressTxt =
          data.proviceName +
          data.cityName +
          data.zoneName
        this.$store.commit('handleAddressInfo', data)
        this.$store.commit('changeAddressReady', true)
        // window.localStorage.removeItem('addressItem')
        return
      }
      // 获取默认地址
      const data = {
        mid: window.localStorage.getItem('uid')
      }
      if (!data.mid) {
        return
      }
      addressH5List(data).then((res) => {
        if (Number(res.status) === 200) {
          res.data = res.data || []
          if (res.data.length > 0) {
            let isDefault = 0
            res.data.forEach(val => {
              isDefault += val.isDefault
              if (val.isDefault == 1) {
                this.addressInfo = val
              }
            })
            if (isDefault > 0) {
              this.addressTxt =
                this.addressInfo.proviceName +
                this.addressInfo.cityName +
                this.addressInfo.zoneName
              this.$store.commit('handleAddressInfo', this.addressInfo)
            } else {
              this.addressInfo = res.data[0]
              this.addressTxt =
                res.data[0].proviceName +
                res.data[0].cityName +
                res.data[0].zoneName
              this.$store.commit('handleAddressInfo', res.data[0])
            }
          }
        }
        this.$store.commit('changeAddressReady', true)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.group_details_box {
  width: 100%;
  min-height: 100px;
  background: #fff;
  margin-top: 12px;

  .group_details_all {
    overflow: hidden;
    margin: 0 12px;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 12px;

    .group_details_l {
      float: left;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;

      .group_details_name {
        color: #999;
      }

      .group_details_title {
        margin-left: 12px;
      }
    }

    .group_details_r {
      float: right;

      img {
        width: 8px;
        height: auto;
      }
    }
  }
}
</style>
