<template>
  <div v-if="groupInfo.length!=0" class="makeup_group">
    <div class="makeup_group_top">
      <div class="makeup_group_l">{{ assembleInfo.needer }}人团,可直接参与</div>
      <div class="makeup_group_r">
        <span @click="isShow=true">查看全部</span>
        <img src="../../assets/images/MoreIocn.png" alt="">
      </div>
    </div>
    <div v-for="(item,index) in groupInfo" :key="index" class="makeup_group_box">
      <div class="makeup_group_img">
        <img :src="item.head_pic" alt="">
        <div class="makeup_group_hg">
          <img src="../../assets/images/hg_imgs.png" alt="">
        </div>
      </div>
      <div class="makeup_group_name">{{ item.nickname }}</div>
      <div class="makeup_group_data">
        <div class="makeup_group_nub">还差 <span>{{ item.need - item.joins }}</span>人参团成功</div>
        <div class="makeup_group_time">剩余{{ leftTime(item.countDown) }}</div>
      </div>
      <div class="makeup_group_btn" @click="btnSon(item)">去参团</div>
    </div>

    <MiniCart v-if="cartData" :goods-status="goodsStatus" :datainfo="cartData" @success="addCartSuccess" />

    <!--弹窗拼团-->
    <div v-show="isShow" class="aleat_box">
      <div class="aleat_box_son">
        <div class="aleat_box_name">
          <h2>正在参团</h2>
          <div>
            <div v-for="(item,index) in groupInfo" :key="index" class="makeup_group_box no">
              <div class="makeup_group_img">
                <img :src="item.head_pic" alt="">
                <div class="makeup_group_hg">
                  <img src="../../assets/images/hg_imgs.png" alt="">
                </div>
              </div>
              <div class="makeup_group_names">{{ item.nickname }}</div>
              <div class="makeup_group_data top">
                <div class="makeup_group_nubs">还差{{ item.need - item.joins }}人</div>
                <div class="makeup_group_times">剩余{{ leftTime(item.countDown) }}</div>
              </div>
              <div class="makeup_group_btn" @click="btnSon(item);isShow=false">去参团</div>
            </div>
            <h5 class="h5">很遗憾，没有更多了～</h5>
          </div>
        </div>
        <img class="imgs" src="../../assets/images/x-on.png" alt="" @click="isShow=false">
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable eqeqeq */
import MiniCart from '@/component/GoodsDetail/MiniCart'

export default {
  name: 'GoogMakeupGroup',
  components: {
    MiniCart
  },
  props: {
    'groupInfo': { type: null, default: null },
    'assembleInfo': { type: null, default: null },
    'goodsStatus': { type: null, default: null }
  },
  data() {
    return {
      isShow: false,
      cartData: null
    }
  },
  mounted() {

  },
  methods: {
    addCartSuccess(data) {
    },
    btnSon: function(t) {
      this.$store.state.showLoading = true

      const obj = {}

      obj.id = this.$route.query.id
      obj.isGroup = 0
      this.cartData = obj

      this.$store.commit('changeShowSku', true)

      // if(t.mid==window.localStorage.getItem('uid')){
      //     Toast("你已经参加拼团");
      //   this.$store.state.showLoading=false
      //   return
      // }else {
      //   this.$store.commit("btnIsShow", true); // 通过vuex 改变 子组件的状态
      //   this.$parent.$refs.btn.dataAll() // 调用子组件 按钮的方法
      //   this.$parent.$refs.btn.$data.isData=t// 调用子组件 按钮的方法
      // }
    },
    // 时间转化
    leftTime: function(t) {
      var second = t
      var hr = Math.floor(second / 3600)
      var hrStr = hr.toString()
      if (hrStr.length == 1) hrStr = '0' + hrStr
      var min = Math.floor((second - hr * 3600) / 60)
      var minStr = min.toString()
      if (minStr.length == 1) minStr = '0' + minStr
      var sec = second - hr * 3600 - min * 60
      var secStr = sec.toString()
      if (secStr.length == 1) secStr = '0' + secStr
      return hrStr + ':' + minStr + ':' + secStr
    }
  }
}
</script>

<style lang="less" scoped>
.makeup_group {
  background: #fff;

  .aleat_box {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 9;

    .aleat_box_son {
      width: 300px;
      height: 360px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 10px;
      transform: translate(-50%, -50%);

      .imgs {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      .aleat_box_name {
        padding: 12px;
        box-sizing: border-box;

        h2 {
          margin-top: 4px;
          text-align: center;
          font-size: 16px;
          margin-bottom: 16px;
          font-weight: 700;
          padding-bottom: 12px;
          border-bottom: 1px solid rgba(0, 0, 0, .1);
        }

        h4 {
          font-size: 14px;
        }

        .h5 {
          text-align: center;
          font-size: 10px;
          color: #999;
          margin-top: 10px;
        }
      }

    }
  }

  .makeup_group_top {
    margin: 12px;
    padding: 12px 0;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .makeup_group_l {
    flex: 1;
    text-align: left;
    font-weight: 500;
  }

  .makeup_group_r {
    flex: 1;
    text-align: right;
    color: #888;
    font-size: 13px;

    img {
      width: 5px;
      margin-left: 4px;
    }
  }

  .makeup_group_box {
    text-align: left;
    overflow: hidden;
    margin: 0 12px;

    padding: 10px 0;
    box-sizing: border-box;
    position: relative;

    .makeup_group_img {
      float: left;
      position: relative;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }

      .makeup_group_hg {
        width: 20px;
        height: 20px;
        background: #e33f44;
        position: absolute;
        right: -2px;
        top: -3px;
        border-radius: 4px;

        img {
          width: 8px;
          height: auto;
        }
      }
    }

    .makeup_group_name {
      line-height: 40px;
      float: left;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 8px;
    }

    .makeup_group_names {
      float: left;
      width: 70px;
      overflow: hidden;
      margin-left: 8px;
      margin-top: 5px;
    }

    .makeup_group_data.top {
      margin-top: 6px;
    }

    .makeup_group_data {
      float: left;

      .makeup_group_nub {
        font-size: 10px;
        margin-top: 1px;

        span {
          color: #e33f44;
        }
      }

      .makeup_group_nubs {
        color: #999;
        font-size: 10px;
      }

      .makeup_group_time {
        color: #999;
        font-size: 10px;
        margin-top: 12px;
      }

      .makeup_group_times {
        color: #999;
        font-size: 10px;
        position: absolute;
        left: 50px;
        top: 36px;
      }
    }

    .makeup_group_btn {
      float: right;
      padding: 7px 12px;
      background: #e33f44;
      box-sizing: border-box;
      border-radius: 12px;
      color: #fff;
      font-size: 10px;
      margin-top: 6px;
    }
  }

  .makeup_group_box.no {
    border: none;
  }
}

</style>
