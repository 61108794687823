<template>
  <div class="img_Advertisement">
    <div class="img_box">
      <div class="list_box">
        <div class="list_ball" :class="{marquee_top:animate}">
          <div v-for="(item, index) in assembleInfo.team_joiner" :key="index" class="list_son">
            <div class="list_li">
              <img :src="item.litpic" alt="">
              <span>{{ item.nickname }}</span>
              <span>{{ dsTime(item.pay_time) }}</span>
              <span>分钟前发起了拼团</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgAdvertisement',
  props: {
    'assembleInfo': {
      type: null,
      default: null
    }
  },
  data() {
    return {
      animate: false
    }
  },
  mounted() {
    this.setInTime = setInterval(this.showMarquee, 3000)
  },
  destroyed() {
    clearInterval(this.setInTime) // 页面销毁时清除定时器
  },
  methods: {
    dsTime: function(t) {
      const d = ((new Date().getTime() / 1000).toFixed(0) - t) % 60
      return d
    },
    showMarquee: function() {
      this.animate = true
      setTimeout(() => {
        this.assembleInfo.team_joiner.push(this.assembleInfo.team_joiner[0])
        this.assembleInfo.team_joiner.shift()
        this.animate = false
      }, 330)
    }
  }

}
</script>

<style scoped lang="less">
div, span, img {
  display: flex;
  box-sizing: border-box;
}

.img_box {
  color: #fff;
}

.list_box {
  position: relative;
  min-width: 300px;
  height: 30px;
  overflow: hidden;
}

.list_ball {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.marquee_top {
  transition: all 0.5s;
  margin-top: -30px
}

.list_son {
  height: 30px;
  line-height: 30px;
  font-size: 10px;
}

.list_li {
  background: rgba(0, 0, 0, .5);
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  padding: 0 8px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    float: left;
    margin-top: 2px;
  }

  span {
    float: left;
    margin-left: 4px;
  }
}
</style>
