<template>
  <div class="all">
    <div class="goods_detail_pt">
      <div>
        <span class="goods_vip_price">¥{{ assembleInfo.team_price }}</span>
        <span class="goods_now_price">原价{{ productInfo.nowPrice }}</span>
        <span class="goods_needer">{{ assembleInfo.needer }}人团</span>
        <span class="goods_needer">已拼{{ assembleInfo.success_count }}件</span>
      </div>
    </div>
    <!--3 规则-->
    <div class="goods_detail_pt_name">
      <div class="goods_detail_pt_s">
        <!---->
        <span class="goods_detail_t">自营</span>
        <span class="goods_detail_pt_n">{{ assembleInfo.act_name }}</span>
      </div>
      <div class="goods_detail_link">
        <img src="../../assets/images/hgimg.png" alt="">
        <span>成团规则 ｜  {{ assembleInfo.needer }}人团 拼团成功团长免费拿</span>
        <img class="goods_img_r" src="../../assets/images/img_right.png" alt="" @click="isShow=true">
      </div>
    </div>
    <!--4 推荐理由-->
    <div class="goods_detail_tj">
      <div class="goods_detail_bj">
        <span class="h6">推荐理由:</span>
        <span class="h5">{{ productInfo.goodsInfo }}</span>
      </div>
    </div>

    <!--弹窗拼团说明-->
    <div v-show="isShow" class="aleat_box">
      <div class="aleat_box_son">
        <div class="aleat_box_name">
          <h2>拼团说明</h2>
          <h4 v-for="(item,index) in assembleInfo.team_rule" :key="index">
            {{ item }}
          </h4>
        </div>
        <img src="../../assets/images/x-on.png" alt="" @click="isShow=false">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogGroup',
  props: {
    productInfo: {
      type: Object,
      default: null
    },
    assembleInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isShow: false
    }
  }

}
</script>

<style lang="less" scoped>
.all {
  text-align: left;
  background: #f6f6f6;

  .aleat_box {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 9;

    .aleat_box_son {
      width: 300px;
      height: 360px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 10px;
      transform: translate(-50%, -50%);

      img {
        width: 30px;
        height: 30px;
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      .aleat_box_name {
        padding: 12px;
        box-sizing: border-box;

        h2 {
          margin-top: 4px;
          text-align: center;
          font-size: 16px;
          margin-bottom: 16px;
          font-weight: 700;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }

  .goods_detail_pt {
    width: 100%;
    height: 42px;
    background: #e33f44;
    line-height: 42px;
    padding: 0 12px;
    box-sizing: border-box;

    .goods_vip_price {
      font-size: 16px;
      color: #fff;
      font-weight: 700;
    }

    .goods_now_price {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      margin-left: 12px;
      font-weight: 500;
    }

    .goods_needer {
      font-size: 12px;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      padding: 2px 4px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: 12px;
    }
  }

  .goods_detail_pt_name {
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
    line-height: 20px;

    .goods_detail_pt_s {
      .goods_detail_t {
        background: #e33f44;
        border-radius: 4px;
        font-size: 10px;
        color: #fff;
        padding: 0px 3px;
        box-sizing: border-box;
        margin-right: 6px;
        font-weight: 500;
      }

      .goods_detail_pt_n {
        font-weight: 700;
      }
    }

    .goods_detail_link {
      padding: 12px;
      box-sizing: border-box;
      background: rgba(255, 0, 0, .19);
      line-height: 16px;
      border-radius: 4px;
      margin-top: 12px;
      color: #e33f44;
      font-size: 13px;
      position: relative;

      img {
        width: 14px;
        height: auto;
        margin-right: 2px;
        vertical-align: -1px;
      }

      .goods_img_r {
        width: 10px;
        height: auto;
        position: absolute;
        right: 16px;
        top: 12px;
      }

    }
  }

  .goods_detail_tj {
    width: 100%;
    margin-top: 12px;
    background: #fff;
    padding: 6px 0;
    box-sizing: border-box;

    .goods_detail_bj {
      width: 94%;
      margin: 6px auto;
      padding: 12px;
      box-sizing: border-box;
      border-radius: 6px;
      background: rgba(171, 171, 156, .1);

      .h6 {
        color: #e33f44;
      }

      .h5 {
        font-size: 12px;
      }
    }
  }
}

</style>
