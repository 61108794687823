<template>
  <div class="goods_pt_main">
    <skeleton v-if="!productInfo" type="detail" />
    <div v-else>

      <!-- 顶部和底部 -->
      <openApp
        :showtig="true"
        :goods-status="goodsStatus"
        :goods-info="productInfo"
        :activity-id="activityId"
        :flash-info="flashInfo"
      />

      <!--1 banner图片 -->
      <van-swipe :autoplay="3000" indicator-color="#ED2E2E">
        <van-swipe-item v-for="(image, index) in productInfo.banner" :key="index">
          <img v-lazy="image" class="banner">
        </van-swipe-item>
      </van-swipe>
      <!--2 名称 价格 等-->
      <googGroup :product-info="productInfo" :assemble-info="assembleInfo" />
      <!--3 拼团-->
      <googMakeupGroup
        v-if="groupInfo!=null"
        :goods-status="goodsStatus"
        :group-info="groupInfo"
        :assemble-info="assembleInfo"
      />
      <!--4 规格 地址 运费-->
      <groupDetails :product-info="productInfo" :area-info="areaInfo" />
      <!--5 推荐-->
      <groupDeRecommend :mend-goods="mendGoods" />
      <!--6 商品详情-->
      <div class="goods_good_content">商品详情</div>
      <div style="width: 100%;text-align: left" v-html="productInfo.goodContent" />
      <!--7 我要开团按钮-->

      <imgAdvertisement class="top" :assemble-info="assembleInfo" />
    </div>
  </div>
</template>

<script>
import OpenApp from '@/component/GoodsDetail/goodsTopBtm'
import googGroup from '@/component/GoodsDetail/googGroup'
import groupDetails from '@/component/GoodsDetail/googGroupDetails'
import groupDeRecommend from '@/component/GoodsDetail/groupDeRecommend'
import googMakeupGroup from '@/component/GoodsDetail/googMakeupGroup'

import imgAdvertisement from '@/component/GoodsDetail/imgAdvertisement'
import skeleton from '@/component/skeleton/index'
import Vue from 'vue'
import { ActionSheet, Icon, Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload).use(Icon).use(ActionSheet)
import { assembleDetail, recommendGoods } from '@/services/api'

export default {
  name: 'GoodsDetailPt',
  components: {
    OpenApp,
    googGroup,
    groupDetails,
    groupDeRecommend,
    googMakeupGroup,
    imgAdvertisement, skeleton
  },
  data() {
    return {
      activityId: '',
      flashInfo: {},
      goodsStatus: 8,
      goodsInfo: {}, // 商品信息首屏
      productInfo: null, // data
      assembleInfo: {}, // 商品list
      groupInfo: {}, // 用户信息
      mendGoods: {}, // 推荐list
      areaInfo: {} // 地址list
    }
  },
  mounted() {
    this.dataAll()
    this.dataList()
  },
  methods: {
    dataAll: function() {
      const data = {
        assembleId: this.$route.query.id, // 团活动ID
        uid: this.$route.query.uid // 用户ID
      }
      assembleDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          const data = res.data
          data.productInfo.assembleInfo = data.assembleInfo
          data.productInfo.productInfo = data.productInfo
          this.productInfo = data.productInfo
          this.assembleInfo = data.assembleInfo
          this.groupInfo = data.groupInfo

          this.times = setInterval(() => {
            this.groupInfo.forEach((item, idx) => {
              if (item.countDown <= 0) {
                clearInterval(this.times)
              } else {
                item.countDown--
              }
            })
          }, 1000)

          this.areaInfo = data.areaInfo
        }
      })
    },
    // 推荐 list
    dataList: function() {
      const data = {
        goodsId: this.$route.query.goodsId,
        uid: this.$route.query.uid
      }
      recommendGoods(data).then((res) => {
        if (Number(res.code) === 200) {
          this.mendGoods = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods_pt_main {
  background: #f6f6f6;
  margin-bottom: 122px;

  .goods_good_content {
    margin-top: 12px;
    background: #fff;
    padding: 12px 0;
    box-sizing: border-box;
  }

  .top {
    position: absolute;
    top: 330px;
    left: 12px;
  }

}

</style>
